* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Poppins;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  /* width: 0; */
  /* height: 0; */
}

/* Optional: Hide scrollbar for Internet Explorer and Edge */
body {
  /* -ms-overflow-style: none;  IE and Edge */
  /* scrollbar-width: none;     Firefox */
}

/* Optional: Hide scrollbar for Firefox */
body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* Login */

.login-left-section {
  height: 100vh;
  width: 100%;
  background-image: url(./Images/login-left-section.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
}

.login-left-section h2 {
  color: white;
  font-size: 48px;
  font-weight: bold;
  font-family: system-ui;
}

.login-left-section p {
  color: white;
  font-family: system-ui;
}

.ask-to-sign h2 {
  font-weight: 600;
  font-family: Poppins;
  justify-content: center;
}

.ask-to-sign h2 img {
  margin-top: -30px;
  width: 60px;
  height: 76px;
}

span.toggle-password-icon {
  position: relative;
  top: -35px;
  right: 20px !important;
  float: right;
  font-size: 21px;
  color: #9a9ab0;
}

.form-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 100px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 12px !important;
}

.form-input:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #f10b80 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #f10b8248 !important;
}

.form-label {
  color: #707070 !important;
  font-weight: 500;
}

.forgot-password {
  color: #EC0B81;
  font-weight: 600;
  margin-top: -45px;
  /* font-family: sans-serif; */
  text-decoration: none !important;
}

.remeber-me {
  color: #707070;
}

.sign-in-btn {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px;
  width: 100%;
  border-radius: 100px;
}

.continue-with {
  text-align: center;
  position: relative;
  color: #707070;
}

.continue-with::after {
  content: "";
  width: 140px;
  height: 1px;
  position: absolute;
  background-color: #b1b1b1;
  top: 13px;
  margin-left: 10px;
}

.continue-with::before {
  content: "";
  width: 140px;
  height: 1px;
  position: absolute;
  background-color: #b1b1b1;
  top: 13px;
  margin-left: -150px;
}

.google-btn {
  padding: 10px 40px;
  border: 1px solid #989898;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  background-color: #0046FB;
  border-radius: 100px;
}

.button-center {
  justify-content: center;
}

.login-right-section {
  height: 100vh;
  overflow-y: scroll;
}

.login-right-section-p {
  font-weight: 600;
}

.login-right-section-p a {
  color: black;
}

/* .login-right-section-p{
  position: absolute;
  top: 20px;
} */
.login-right-section-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 86%;
}

#input-outline:focus-visible {
  box-shadow: 0px 4px 6px #d1007e !important;
  border: none !important;
  outline-color: #d1007e !important;
}

.otp-span {
  text-align: center;
  color: #707070;
  margin: 0;
  padding: 2px;
}

.upload-file {
  border: 2px dashed #707070 !important;
  /* padding: 40px !important; */
  border-radius: 100px !important;

  background-color: transparent !important;
  overflow: hidden;
}

.upload-file-icon {
  font-size: 25px;
  color: #707070;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #f10b80 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #f10b8248 !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  height: 50px;
  /* border-radius: 5400px !important; */
}

.custom-header-compare {
  color: #707070 !important;
  background-color: white !important;
  font-weight: 700 !important;
  box-shadow: 1px 1px 20px rgb(219, 219, 219) !important;
  position: fixed;
  z-index: 1050;
  top: 0;
  /* margin-bottom: 100px !important; */

}
.custom-header-compare-dark{
  box-shadow: none !important;
}

.nav-typography {
  border-radius: 0px !important;
  font-weight: 500 !important;
  color: #183b56 !important;
  font-family: system-ui !important;
  margin: 0px 20px -28px 0px !important;
  padding-bottom: 22px !important;
  text-transform: capitalize !important;
}

/* .logo-nav{

} */
.link_decorations_white {
  text-decoration: none;
  color: rgb(211, 211, 211);
}

.about_us_ul li {
  color: #5a7184;
  /* font-family: "Rufina"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
  margin-left: -30px;
}

.enter_email {
  background-color: white;
  color: black;
  padding: 10px 70px;
  border-radius: 100px;
}

.subscribe_btn {
  background-color: #29ace3;
  color: white;
  padding: 10px 70px;
  border-radius: 100px;
}

.footer_logo img {
  width: 245px !important;
  max-width: 245px !important;
}

.copy_right {
  color: #5a7184;
  font-size: 15px;
}

p {
  margin-top: 0;
  margin-bottom: 0px !important;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0px !important;
}

.footer-main {
  background-color: #fbf5fa;
}

.footer_logo img {
  width: 100px !important;
}

.footer-lorem {
  color: #5a7184;
  font-size: 15px;
  font-family: system-ui;
  line-height: 28px;
  font-weight: 400;
}

.about_us_ul li a {
  color: #5a7184;
  /* font-family: "Rufina"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
  /* margin-left: -30px; */
}

.search-note {
  height: auto;
  background-color: white;
  /* min-width: 300px !important; */
  width: 700px;
  border-radius: 20px;
  box-shadow: 1px 1px 20px rgb(219, 219, 219) !important;
}
.search-note-dark{
  box-shadow: none !important;
}



@media screen and (min-width: 70px) and (max-width: 1000px) {
  .search-note {
    width: auto;
  }
}

.background-main {
  background-color: #f8f8f8;
}

.search-note-h2 {
  font-size: 22px;
  text-align: center;
  font-weight: 600;
}

.robert-p {
  font-weight: 500;
  color: #535353;
}

.upload-file-photo {
  border: 2px dashed #c8c8c8 !important;
  padding: 30px 15px !important;
  border-radius: 10px !important;
  background-color: transparent !important;
  flex-direction: column;
  color: #c8c8c8 !important;
  font-size: 15px;
}

.upload-file-icon-photo {
  font-size: 25px;
  color: #c8c8c8;
}

.save-draft-btn {
  color: white;
  background-color: black;
  padding: 10px 0px;
  border-radius: 100px;
  width: 200px;
}


.hashtag-modal-search-button {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  float: right;
  width: 200px;
  text-align: center;
}

.search-btn {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  float: right;
  width: 200px;
  text-align: center;
}

@media screen and (min-width: 70px) and (max-width: 700px) {
  .search-btn {
    float: none;
    width: 100%;
    margin: auto;
  }

  .save-draft-btn {
    margin: auto;
    width: 100%;
  }
  .ask-to-sign{
    padding: 0px 10px !important;
  }

  .perposal-accept-btn {
    margin: auto;
    width: 100% !important;
    margin-bottom: 15px;
  }
  .login-left-section{
    display: none !important;
  }

  .save-draft-btn {
    margin: auto;
    width: 100% !important;
    margin-top: 20px !important;
  }

  .side-nav-info {
    width: 50% !important;
  }

  .side-nav-info-responsive {
    width: 50% !important;
  }
  .view-details-image-none{
    display: none;
  }
}

.save-draft-btns {
  /* justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center; */
}

.search-bar {
  /* padding: 10px; */
  border: 1px solid #9a9ab0;
  border-radius: 100px;
  color: #9ca4ab;
  width: 250px !important;
  overflow: hidden;
  background-color: white;
}

.search-bar input {
  outline: none;
  background-color: transparent;
  width: 100%;
}

.search-bar-right {
  position: absolute;
  right: 0;
}

.card_heading {
  font-size: 20px !important;
  /* font-weight: 700 !important;  */
  color: #434243;
}

.view_profile_b {
  color: #29ace3 !important;
}

.enquire_now_b {
  color: #da2700 !important;
  float: right;
}

.home-card-sw {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 13px !important;
  box-shadow: 1px 1px 5px #00000021 !important;
  overflow: hidden;
  max-width: 345px;
}

.open-now-btnn {
  position: absolute;
  padding: 5px 5px;
  border-radius: 100px;
  margin: 0px;
  right: 0;
  color: green;
}

.card-pera {
  font-size: 17px !important;
}

.breadcrumbs a {
  color: #6f6f6f;
  text-decoration: none;
  position: relative;
  margin-top: -20px !important;
}

.user-profile {
  border-radius: 100px;
  width: 120px;
  height: 120px;
}

.user-name {
  font-weight: 700;
  font-size: 22px;
}

.search-btn-invitation {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  float: right;
  width: 200px;
  text-align: center;
}

.user-description {
  color: #7f7f7f;
  line-height: 29px;
  font-weight: 600;
}

.user-description-rating {
  color: #7f7f7f;
}

.link-decoration {
  text-decoration: none;
}

.breadcrumbs {
  position: relative;
  top: -33px;
}

.bar-icon {
  background-color: #ededed;
  border-radius: 100px;
  padding: 5px 10px;
  float: right;
  color: #656565;
  /* margin: 10px; */
}

.chat-profile {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  /* margin-top: 8px; */
}

.chat-media {
  width: 100px !important;
  height: auto;
  border-radius: 10px;
}

.chat-content {
  background-color: #e6f3ff;
  max-width: 80%;
  border-radius: 0px 15px 0px 15px;
}

.user-chat-content {
  background-color: #fddeee;
  max-width: 80%;
  border-radius: 0px 15px 0px 15px;
}

.user-side-chat-fixed {
  justify-content: right;
}

.are-you-sure-heading {
  text-align: center;
  color: white;
  background-image: linear-gradient(to right, #f10b80, #830d9a);
}

.modal-description-p {
  color: #898989;
  text-align: center;
}

.modal-button {
  position: relative;
  top: 26px;
}

.border-rduis {
  border-radius: 10px;
  overflow: hidden;
}

.search-bar-image {
  width: 30px;
}

.inner-shadow {
  box-shadow: 1px 1px 15px rgb(217 217 217) !important;
  height: auto;
  border-radius: 10px;
}

/* .tabs-tab:focus{
  background-color: red !important;
}
.tabs-tab:active{
  background-color: red !important;
} */

.Mui-selected {
  /* background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white !important;
  padding: 10px !important;
  min-height: auto !important; */
}

/* VerticalNavTabs.css */
#vertical-nav .nav-link {
  background-color: #f8f9fa;
  /* Background color for unselected tabs */
  border: 1px solid transparent;
  /* Border for unselected tabs */
  border-radius: 0;
  /* Optional: Remove border radius */
}

#vertical-nav .nav-link.active {
  background-color: #007bff;
  /* Background color for selected tab */
  border-color: #007bff;
  /* Border color for selected tab */
  color: #fff;
  /* Text color for selected tab */
}

/* Optional: Hover effect */
#vertical-nav .nav-link:hover {
  background-color: #e9ecef;
  /* Background color on hover */
}

.tabs-tab {
  border-radius: 100px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  -webkit-box-pack: start !important;
  display: -webkit-inline-box !important;
}

.css-10d9dml-MuiTabs-indicator {
  display: none;
}

.MuiTabs-indicator {
  display: none;
}

.side-bar-profile {
  width: 90px;
  height: 90px;
  border-radius: 100px;
  /* box-shadow: 1px 1px 7px #838383; */
  /* border: 2px solid #C30C8C; */
}

.side-bar-name h2 {
  font-size: 20px;
  color: #4b4b4b;
  font-weight: 700;
  text-align: center;
}

.side-bar-name p {
  color: #898989;
  text-align: center;
}

.side-bar-name h3 {
  text-align: center;
  background: linear-gradient(to right, #f10b80, #830d9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}

.custom-tab-panel {
  padding: 0px !important;
}

.MuiBox-root {
  /* Add your custom styles here */
  padding: 0px !important;
}

.overview-heading {
  color: #2f2f2f;
  font-size: 18px;
  font-weight: 600;
}

.side-bar-name-nav h2 {
  font-size: 20px;
  color: #4b4b4b;
  font-weight: 700;
}

.side-bar-name-nav p {
  color: #898989;
}

.side-nav-info {
  color: #303030;
}

.change-phone-number-btn {
  color: #820d9b;
  padding: 7px 20px;
  border: 2px solid #820d9b;
  border-radius: 10px;
}

.ask-to-sign-scroll {
  overflow-y: scroll;
  max-height: 80vh;
}

.payment-btn {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 70px;
  /* width: 100%; */
  border-radius: 100px;
}

.pay-now-btn {
  color: white;
  padding: 10px 70px;
  border-radius: 100px;
  background-color: black;
}

.overview-heading-wallet {
  color: #373737;
  font-size: 20px;
  font-weight: 600;
}

.draft-driver {
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}

.overview-heading-h5 {
  color: #c70b89;
  font-size: 15px;
}

.contracts-nav-heading {
  background-image: none !important;
  color: black !important;
  text-transform: capitalize !important;
  font-size: 17px !important;
}

.MuiTabs-indicator {
  background-color: #b50b8d !important;
}

.MuiTabs-flexContainer {
  justify-content: center !important;
}

.contracts-nav-image {
  width: 80px !important;
  border-radius: 100px;
}

.contracts-nav-card {
  text-align: left;
}

.contracts-nav-card h4 {
  font-size: 18px;
}

.view-contract {
  background: linear-gradient(to right, #f10b80, #830d9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  float: right;
  font-size: 16px;
  font-weight: 600;
}

.activecontract h4 {
  font-size: 18px;
}

.activecontract p {
  color: #7d7d7d;
}

/* .css-b8tju9{
  z-index: 999 !important;
} */

.form-label-dark {
  color: #000000 !important;
  font-weight: 500;
}

.x-mark {
  float: right;
  width: 22px;
  cursor: pointer;
}

.custom-modal-style {
  width: 100px !important;
}

.form-input-modal {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 12px !important;
  border-radius: 10px !important;
}

.form-input-modal:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #f10b80 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #f10b8248 !important;
}

.my-dispute-card {
  border: 1px solid #dddddd;
}

.in-review {
  padding: 5px 10px;
  background-color: #d9f1ff;
  color: #02a0e3;
  border-radius: 100px;
}

.is-resolved {
  padding: 5px 10px;
  background-color: #a3edb7;
  color: #0c7300;
  border-radius: 100px;
}

.transection-info h2 {
  font-size: 16px !important;
  color: black;
}

.transection-info-icon {
  width: 45px;
}

.transection-info-doller {
  color: #00c013;
  font-weight: bold;
  position: relative;
  top: -35px;
}

.transection-info-doller-withdrawal {
  color: #ff1d1d;
  font-weight: bold;
  position: relative;
  top: -35px;
}

.withdraw-btn {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  width: 200px;
  text-align: center;
  float: right;
  position: relative;
  top: -50px;
}

.withdraw-btn-ok {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  width: 200px;
  text-align: center;
  /* float: right; */
  position: relative;
  /* top: -50px; */
}

.withdraw-btn-ok {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  width: 200px;
  text-align: center;
  /* float: right; */
  position: relative;
  /* top: -50px; */
}

.cancel-btn {
  background: linear-gradient(to right, #f10b80, #830d9a);
  /* color: white; */
  padding: 10px 30px;
  border-radius: 100px;
  width: auto;
  text-align: center;
  /* float: right; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 2px solid #f10b80;
}

.logout-btn {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 12px 30px;
  border-radius: 100px;
  text-align: center;
  position: relative;
}

.landing-section {
  background-color: #fbf5fa;
  height: auto;
  border-bottom: 1px solid rgb(126, 126, 126);
}

.build-business h2 {
  color: #183b56;
  font-size: 45px;
  font-weight: bold;
}

.build-business p {
  color: #5a7184;
}

.animation-line {
  position: absolute;
  right: 20px;
  top: 100px;
  animation-name: animationline;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
}

@keyframes animationline {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-right: 300px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-rating {
  position: absolute;
  margin-left: 0px;
  top: 150px;
  animation-name: animationrating;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 1;
}

@keyframes animationrating {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: 200px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-done {
  position: absolute;
  right: 350px;
  top: 200px;
  animation-name: animationdone;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 1;
}

@keyframes animationdone {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: 100px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-visitor {
  position: absolute;
  right: 300px;
  top: 400px;
  animation-name: animationvisitor;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 11;
}

@keyframes animationvisitor {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: 50px;
    margin-right: 100px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-icon {
  position: absolute;
  right: 950px;
  top: 500px;
  animation-name: animationicon;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 1;
}

@keyframes animationicon {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation {
  position: absolute;
  left: 0;
  top: 400px;
  /* animation-name: animationicon; */
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 1;
}

.animtion-girl {
  position: relative;
  z-index: 10;
  width: 500px;
  margin: auto;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  #animation-none {
    display: none;
  }

  .customer-saying {
    background-image: none !important;
  }

  .customer-saying-card {
    margin-top: 0px !important;
  }

  .subscribe-search-section {
    width: auto !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .animation-done {
    position: absolute;
    right: 0px;
    top: 400px;
    animation-name: animationdone;
    animation-duration: 10s;
    animation-delay: -2s;
    animation-iteration-count: infinite;
    z-index: 1;
  }

  .animation-visitor {
    position: absolute;
    right: 200px;
    top: 400px;
    animation-name: animationvisitor;
    animation-duration: 10s;
    animation-delay: -2s;
    animation-iteration-count: infinite;
    z-index: 11;
  }

  .animation-icon {
    position: absolute;
    right: 600px;
    top: 400px;
    animation-name: animationicon;
    animation-duration: 10s;
    animation-delay: -2s;
    animation-iteration-count: infinite;
    z-index: 1;
  }
}

.why-choose-h5 {
  text-align: center;
  color: #183b56;
  font-size: 28px;
  font-weight: bold;
}

.why-choose-card {
  box-shadow: 1px 1px 10px rgb(229, 229, 229);
  border-radius: 10px;
}

.why-choose-card h5 {
  color: #183c56;
}

.why-choose-card p {
  color: #5a7184;
  text-align: justify;
}

.trusted-us {
  background-color: #f8fcfd;
  border-bottom: 1px solid rgb(125, 125, 125);
}

.trusted-us-image {
  width: 400px;
  margin: auto;
}

.trusted-us p {
  color: #5a7184;
  text-align: justify;
}

.blog-card {
  box-shadow: 1px 1px 10px rgb(229, 229, 229);
  border-radius: 10px;
  background-color: #f8fcfd;
}

.blog-card img {
  border-radius: 10px;
}

.blog-card p {
  color: #766a6a;
}

.blog-card h5 {
  color: #183b56;
}

.blog-card button {
  color: #183b56;
}

.blog-card-icon {
  font-size: 14px;
}

/* .customer-saying {
  height: 700px;
  background-image: url(./Images/saying.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
} */

.customer-saying-p {
  color: #183b56;

  font-weight: 600;
  line-height: 25px;
}

.customer-saying-william {
  color: #820d9b;
  font-weight: 700;
}

.customer-saying-card {
  margin-top: 120px;
}

.subscribe-section {
  background-image: url(./Images/Subscribe.png);
  border-radius: 20px;
}

.subscribe-search-section {
  width: 500px;
  background-color: white;
  height: auto;
  border-radius: 10px;
}

.subscribe-section h2 {
  color: white;
  font-size: 40px;
  max-width: 400px;
  margin: auto;
  font-weight: bold;
}

.subscribe-input {
  box-shadow: none !important;
}

.subscribe-search-section input {
  height: 40px;
  outline: none;
}

.subscribe-search-section button {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 17px 40px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  float: right;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  .subscribe-search-section input {
    width: 74px !important;
  }

  .message-time-new {
    /* float: right; */
    font-size: 12px;
    position: absolute !important;
    /* position: relative; */
    top: 11px !important;
    right: 0px !important;
    line-height: 7px !important;
    /* right: -19px; */
    /* left: 140px; */
    text-align: right;
    font-weight: 400;
    width: 96px;
    color: #797c7b;
  }
}

@media screen and (min-width: 0px) and (max-width: 400px) {
  .subscribe-search-section input {
    width: 24px !important;
  }
}

.MuiBarElement-root {
  fill: #bc0c8c !important;
}

.total-earning {
  background-color: #f9f9f9;
  border-radius: 10px;
  width: 200px !important;
  text-align: left;
}

.user-identification-icon {
  width: 17px;
}

.identification-modal {
  cursor: pointer;
}

.video-img {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin: auto;
}

.round-bold {
  font-weight: 500;
  color: #4c4c4c;
}

.stop-btn {
  background-color: black;
  color: white;
  padding: 8px 40px;
  border-radius: 100px;
  text-align: center;
  position: relative;
  width: 50%;
}

@media screen and (min-width: 70px) and (max-width: 776px) {
  .MuiBox-root {
    width: 100% !important;
  }

  .inbox-search-button {
    justify-content: center !important;
  }

  .pay-now-btn-responsive {
    width: 100%;
  }
}

.image-inline {
  display: inline !important;
}

.Mui-selected {
  /* background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white !important;
  border-bottom: 1px solid #a40c92 !important; */
}

.MuiButtonBase-root {
  /* padding: 10px !important; */
  min-height: auto !important;
  /* color: black !important; */
  align-items: start !important;
}

/* .tabs-tab{
  color: white !important;
} */
.contracts-nav-heading {
  color: rgb(60, 60, 60) !important;
}

hr {
  margin: 1rem 0 !important;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

ul.about_us_ul {
  margin-left: 31px;
}

.ask-to-sign h2 {
  /* font-weight: 700; */
  justify-content: center;
  font-size: 25px;
}

.delete-draft {
  width: 17px;
  float: right;
  /* position: relative; */
  right: 0;
  margin: 0px 10px;
  cursor: pointer;
}

.fillter-btn {
  border: 2px solid #eeeeee !important;
  border-radius: 100px !important;
  color: #878787;
  padding: 10px 30px !important;
  display: flex !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 1.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  margin-top: 9px;
  border-left: 0.3em solid transparent;
  top: 10px;
}

/* animation start  */

.animation-saying-1 {
  position: absolute;
  /* right: 20px; */
  top: 270px;
  left: 250px;
  animation-name: animationsaying1;
  animation-duration: 15s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}

@keyframes animationsaying1 {
  /* 0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: 300px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  } */


  0% {
    transform: rotate(0deg) translateY(250px) rotate(0deg);
  }

  50% {
    transform: rotate(180deg) translateY(300px) rotate(-180deg);
  }

  100% {
    transform: rotate(0deg) translateY(250px) rotate(0deg);
  }
}

.animation-saying-2 {
  position: absolute;
  /* right: 20px; */
  top: 260px;
  left: 270px;
  animation-name: animationsaying2;
  animation-duration: 20s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}

@keyframes animationsaying2 {
  /* 0% {
    margin-right: 0px;
    margin-top: 0px;
    
  }

  50% {
    margin-top: 400px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  } */

  0% {
    transform: rotate(0deg) translateY(200px) rotate(0deg);
  }

  50% {
    transform: rotate(180deg) translateY(250px) rotate(-180deg);
  }

  100% {
    transform: rotate(0deg) translateY(200px) rotate(0deg);
  }
}

.animation-saying-3 {
  position: absolute;
  /* right: 20px; */
  top: 250px;
  left: 280px;
  animation-name: animationsaying3;
  animation-duration: 10s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}

@keyframes animationsaying3 {
  /* 0% {
    margin-right: 0px;
    margin-top: 0px;
    margin-left: 150px;

  }

  15% {
    margin-top: -50px;
    margin-left: 125px;
  }

  25% {
    margin-top: -200px;
    margin-left: 100px;
  }

  50% {
    margin-top: -400px;
    margin-left: 150px;
  }

  75% {
    margin-top: -200px;
    margin-left: 100px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
    margin-left: 200px;

  } */

  /* from {
    transform: rotate(0deg) translateY(200px) rotate(0deg);
  }
  to {
    transform: rotate(180deg) translateY(200px) rotate(-180deg);
  } */

  0% {
    transform: rotate(0deg) translateY(150px) rotate(0deg);
  }

  50% {
    transform: rotate(180deg) translateY(200px) rotate(-180deg);
  }

  100% {
    transform: rotate(0deg) translateY(150px) rotate(0deg);
  }
}

.animation-saying-4 {
  position: absolute;
  /* right: 20px; */
  top: 230px;
  left: 0px;
  animation-name: animationsaying4;
  animation-duration: 15s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}

@keyframes animationsaying4 {


  0% {
    transform: rotate(0deg) translateY(-250px) rotate(0deg);
  }

  50% {
    transform: rotate(180deg) translateY(-300px) rotate(-180deg);
  }

  100% {
    transform: rotate(0deg) translateY(-250px) rotate(0deg);
  }
}

.animation-saying-5 {
  position: absolute;
  /* right: 20px; */
  top: 200px;
  left: 0px;
  animation-name: animationsaying5;
  animation-duration: 20s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}

@keyframes animationsaying5 {
  /* 0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: -400px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  } */

  0% {
    transform: rotate(0deg) translateY(-200px) rotate(0deg);
  }

  50% {
    transform: rotate(180deg) translateY(-250px) rotate(-180deg);
  }

  100% {
    transform: rotate(0deg) translateY(-200px) rotate(0deg);
  }
}

.animation-saying-6 {
  position: absolute;
  /* right: 20px; */
  top: 200px;
  left: 0px;
  animation-name: animationsaying6;
  animation-duration: 10s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}

/* Index.css */
.border-pink {
  border-color: #f10b80 !important;
}

.border-purple {
  border-color: #830d9a !important;
}

/* Index.css */
.form-input.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }


}

@keyframes animationsaying6 {
  /* 0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: -400px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  } */

  0% {
    transform: rotate(0deg) translateY(-150px) rotate(0deg);
  }

  50% {
    transform: rotate(180deg) translateY(-200px) rotate(-180deg);
  }

  100% {
    transform: rotate(0deg) translateY(-150px) rotate(0deg);
  }
}

@media screen and (min-width: 70px) and (max-width: 1000px) {
  .none-section {
    display: none;
  }
}

.dropdown-custom {
  position: relative;
  /* right: -215px; */
  top: -100px;
}

@media screen and (min-width: 320px) and (max-width: 520px) {
  .barchart {
    width: 100% !important;
    position: absolute;
  }
}

.back-shadow {
  background-color: white;
  /* min-width: 300px !important; */
  border-radius: 20px;
  box-shadow: 1px 1px 20px rgb(219, 219, 219) !important;
}

.user-chat {
  text-align: left;
  border-radius: 10px;
}

.user-chat h4 {
  font-size: 19px;
  color: black;
  font-weight: 600;
}

.user-chat-profile {
  width: 65px;
  border-radius: 100px;
}

.contract-id {
  font-size: 14px;
  /* -webkit-text-fill-color: transparent; */
  color: #f10b80;
  font-weight: 500;
}

.user-chat p {
  font-size: 14px;
  color: #252525;
}

.user-chat:hover {
  background-color: #fde1ef;
  transition: all 0.2s;
  cursor: pointer;
}

.message-time {
  /* float: right; */
  /* font-size: 15px; */
  font-weight: 400;
  color: #797c7b;
}

.message-count {
  float: right;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff !important;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  border-radius: 100px;
}

.active-status {
  background: #f10b80;
  border-radius: 100px;
  position: relative;
  height: 10px;
  width: 10px;
  left: 80%;
  bottom: 18%;
}

/* .viewport-chat{
  height: 50vh;
  overflow-y: scroll;
} */
.message-favorite {
  float: right;
  font-size: 23px;
  margin-top: 7px;
  /* margin-right: -20px; */
  transform: rotate(270deg);
  font-weight: 500 !important;
  color: #7c7c7c;
}

#drop-arrow {
  cursor: pointer;
  display: none;
}

.user-chat:hover #drop-arrow {
  display: inline;
}

.dropdown-chat {
  display: block;
  /* Ensures the dropdown menu is visible when rendered */
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 50px;
  /* Adjust based on your layout */
  right: 10px;
  /* Adjust based on your layout */
  padding: 10px;
  border-radius: 4px;
}

.dropdown-chat p {
  margin: 0;
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-chat p:hover {
  background-color: #f1f1f1;
}

.dropdown-chat p {
  color: #838383;
  font-size: 15px;
}

.favorite-heart-icon {
  color: #e10b83;
}

.delete-user-icon {
  color: black;
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 200px;
  /* Adjust width as needed */
}

.custom-select__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  cursor: pointer;
}

.custom-select__arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ffffff;
}

.custom-select__options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1000;
}

.custom-select__option {
  padding: 10px;
  cursor: pointer;
}

.custom-select__option.selected {
  background-color: #f0f0f0;
}

.check-icon {
  margin-right: 5px;
}

.side-bar-name-nav h6 {
  background: linear-gradient(to right, #f10b80, #830d9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* Add this for non-webkit browsers */
  color: transparent;
  /* Fallback for browsers that do not support background-clip */
  font-size: 16px;
  font-weight: 700;
}

.side-bar-name-h5 {
  font-size: 16px;
  color: #303030;
  font-weight: 700;
}

.refer_by_info h5 {
  font-size: 16px;
  /* font-weight: 700; */
  color: #02a0e3;
}

.refer_by_info p {
  line-height: 38px;
  font-weight: 500;
}

.refer_by_info span {
  line-height: 38px;
  font-weight: 500;
  background-color: #d9f1ff;
  padding: 8px 15px;
  border-radius: 100px;
  color: #02a0e3;
  font-weight: 500;
}

.job-created p {
  color: #000000;
  font-weight: 600;
  line-height: 25px;
}

.job-created p span {
  color: #767676;
  font-weight: 600;
}

.job-created h5 {
  color: #f10b80;
  /* Fallback for browsers that do not support background-clip */
  font-size: 16px;
}

.popup-msg-user-profile {
  width: 80px;
  height: 70px;
  border-radius: 100px;
}

.popup-msg {
  background-color: #e6fbff;
  border-radius: 10px;
  border: 2px solid #02a0e3;
}

.perposal-section h5 {
  color: #02a0e3;
  text-align: center;
}

.perposal-section p {
  color: #787878;
  text-align: center;
}

.perposal-accept-btn {
  background-color: #02a0e3;
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  float: right;
  width: 200px;
  text-align: center;
}

.perposal-info {
  color: #02a0e3;
  cursor: pointer;
}

.logo-nav {
  width: 70px !important;
}

.upload-image {
  height: 100% !important;
  width: 100% !important;
  /* background-size: unset; */
  object-fit: cover;
}

.upload-file {
  height: 100px;
  width: 100px;
  /* margin: auto; */
}

.tabs-tab {
  text-align: left !important;
}

.notification-list {
  padding: 10px;
}

.notification-list-user {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin: 4px 10px;
}

.notification-list-user-p {
  font-weight: 400;
}

.notification-list-user-time {
  float: right;
  font-weight: 400;
}

.notification-list {
  position: absolute;
  top: 60px;
  right: 10px;
  height: 351px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  border-radius: 8px;
  width: 450px;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden;
}

.perposal-accept-reject-btn {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  float: right;
  width: 200px;
  text-align: center;
}

.all-dispute-list {
  max-height: 50vh;
  /* background: rebeccapurple; */
  overflow: scroll;
  overflow-x: hidden;
}

.infinite-scroll-component {
  overflow: unset !important;
}

.react-tel-input {
  width: fit-content !important
}

.side-nav-info-span {
  line-height: 38px;
  font-weight: 500;
  background-color: #d9f1ff;
  padding: 8px 15px;
  border-radius: 100px;
  color: #02a0e3;
  font-weight: 500;
}

.side-nav-info-details {
  color: #979797;
}

.side-nav-info-dot {
  color: black;
  font-weight: 600;
  float: right;
}

/* .form-input {
  overflow: hidden; 
  resize: none; 
} */

/* For webkit browsers (Chrome, Safari) */
.form-input::-webkit-scrollbar {
  /* display: none; */
}

/* For IE and Edge */
.form-input {
  /* -ms-overflow-style: none;  Hide scrollbar */
}

/* For Firefox */
.form-input {
  /* scrollbar-width: none; Hide scrollbar */
}

.notifaction-shadow {
  box-shadow: 1px 1px 7px #b9b9b9;
  min-height: 80px;
  border-radius: 15px;
  padding: 10px;
}

.radio-message {
  width: 16px;
  height: 16px;

  margin-right: 8px;
}

.radio-message-new {
  display: flex;
  align-items: center;
}

.radio-message-new {
  font-weight: 400;
}

.no-da {
  width: 310px !important;
  margin: auto;
}

.logou-img {
  width: 250px;
  margin: auto;
}

/* index.css */
/* App.css */

/* Light mode styles */

/* Dark mode styles */
/* .dark-mode {
  background-color: black !important;
  color: white !important;
} */

.Refer-btn {
  background-color: #02A0E3;
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  width: 200px;
  text-align: center;
}

.notifications-class {
  outline: 0;
  /* height: 70px; */
  /* width: 80px; */
  padding: 12px 0px !important;
}

.user-profile-new {
  border-radius: 100px;
  width: 80px;
  height: 80px;
}

.comment-users {
  color: #515151;
}

/* .dark-mode{
  color: white !important;
  background-color: black !important;
} */
/* General styles */
.App {
  text-align: center;
  background-color: white;
  color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Dark mode styles */
/* .dark-mode {
  background-color: black;
  color: white;
} */
/* Example CSS for light and dark themes */



/* .login-right-section-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 86%;
} */
.clear-fillter {
  background-color: black;
  width: 148px !important;
  border-radius: 100px;
  height: 55px;
  float: right;
  position: absolute;
  right: 264px;
  top: 25px;
  /* float: right; */
  color: white;
}



.fillter-text-bar {
  filter: saturate(1.5) !important;
}

.client-side-chat::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.full-width-image-video {
  min-width: 465px;
}

.z-index-999 {
  z-index: 999;
}

.sign-up-text {
  font-weight: 700;
}

button#vertical-tab-8 {
  opacity: inherit;
  color: #676666;
}

.country-code {
  border-radius: 50px;
  width: 135px !important;
  padding: 12.5px 14px 12.5px 60px;
  border: 1px solid #CACACA;
}

.border-reduis-null {
  border-radius: 20px !important;
}

@media screen and (min-width:320px) and (max-width:776px) {
  /* .overflow-reponsive {
    width: 59% !important;
    overflow: scroll;
  } */

  .search-bar {
    margin-top: -50px !important;
    position: relative;
  }

  .clear-fillter {
    margin-top: -50px;
    position: absolute;
    top: -42px;
    left: 0;
  }

  .breadcrumbs {
    visibility: hidden;
  }

  .home-card-sw {
    margin: auto;
  }

  .payment-btn {
    float: none !important;
  }

  .login-left-section {
    height: 95vh !important;
  }

  .logo-nav {
    width: 239px !important;
  }

  .notifaction-icon {
    position: relative;
    left: -20px;
  }

  .modal-button {
    width: 100%;
  }

  .perposal-accept-reject-btn {
    width: 100%;
    margin-bottom: 24px;
  }

  .forgot-password {
    margin-top: -7px;
    position: relative;
    width: 100%;
    /* left: 0; */
  }

}

@media screen and (min-width:776px) and (max-width:1000px) {
  .forgot-password {
    margin-top: -14px;
  }
}

.user-chat-name {
  font-size: 14px;
  color: #ca0474;
  position: relative;
  top: -5px;
  line-height: 6px !important;
}

.message-time {
  /* float: right; */
  font-size: 12px;
  position: relative;
  top: 7px;
  line-height: 7px;
  /* right: -19px; */
  text-align: right;
}

.message-time-new {
  float: right;
  font-size: 12px;
  position: relative;
  top: 7px;
  line-height: 7px;
  /* right: -19px; */
  text-align: right;
  font-weight: 400;
  color: #797c7b;
}

.message-time-left {
  /* float: right; */
  font-size: 12px;
  position: relative;
  top: 7px;
  line-height: 7px;
  /* right: -19px; */
  text-align: right;
}

.identificationn-hide {
  display: none;
}

.card-hover {
  transition: transform 0.3s ease-in-out;
  /* You can adjust the duration and easing function */
}


.card-hover:hover {
  transform: scale(1.1);
}

.Login-btn {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  float: right;
  width: 200px;
  font-weight: 600;
  text-align: center;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .animation-saying-4 {
    position: absolute;
    /* right: 20px; */
    top: 230px;
    left: 0px;
    animation-name: animationsaying4;
    animation-duration: 15s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
  }

  @keyframes animationsaying4 {


    0% {
      transform: rotate(0deg) translateY(-250px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(-220px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(-250px) rotate(0deg);
    }
  }

  .animation-saying-1 {
    position: absolute;
    /* right: 20px; */
    top: 270px;
    left: 250px;
    animation-name: animationsaying1;
    animation-duration: 15s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
    margin-top: 50px;
  }

  @keyframes animationsaying1 {



    0% {
      transform: rotate(0deg) translateY(50px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(420px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(50px) rotate(0deg);
    }
  }

  .animation-saying-2 {
    position: absolute;
    /* right: 20px; */
    top: 260px;
    left: 270px;
    animation-name: animationsaying2;
    animation-duration: 20s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
  }

  @keyframes animationsaying2 {

    0% {
      transform: rotate(0deg) translateY(100px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(250px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(100px) rotate(0deg);
    }
  }

  .animation-saying-3 {
    position: absolute;
    /* right: 20px; */
    top: 250px;
    left: 280px;
    animation-name: animationsaying3;
    animation-duration: 10s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
  }

  @keyframes animationsaying3 {


    0% {
      transform: rotate(0deg) translateY(150px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(300px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(150px) rotate(0deg);
    }
  }

  .animation-saying-6 {
    position: absolute;
    /* right: 20px; */
    top: 200px;
    left: 0px;
    animation-name: animationsaying6;
    animation-duration: 10s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
  }

  @keyframes animationsaying6 {


    0% {
      transform: rotate(0deg) translateY(-170px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(-10px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(-170px) rotate(0deg);
    }
  }

  .animation-saying-5 {
    position: absolute;
    /* right: 20px; */
    top: 200px;
    left: 0px;
    animation-name: animationsaying5;
    animation-duration: 20s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
  }

  @keyframes animationsaying5 {

    0% {
      transform: rotate(0deg) translateY(-200px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(-190px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(-200px) rotate(0deg);
    }
  }


}




@media screen and (min-width: 776px) and (max-width: 1200px) {
  .animation-saying-4 {
    position: absolute;
    /* right: 20px; */
    top: 200px;
    left: 0px;
    animation-name: animationsaying4;
    animation-duration: 15s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
  }

  @keyframes animationsaying4 {


    0% {
      transform: rotate(0deg) translateY(-250px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(-150px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(-250px) rotate(0deg);
    }
  }

  .animation-saying-1 {
    position: absolute;
    /* right: 20px; */
    top: 200px;
    left: 150px;
    animation-name: animationsaying1;
    animation-duration: 15s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
    margin-top: 50px;
  }

  @keyframes animationsaying1 {


    0% {
      transform: rotate(0deg) translateY(40px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(300px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(40px) rotate(0deg);
    }
  }

  .animation-saying-2 {
    position: absolute;
    /* right: 20px; */
    top: 200px;
    left: 220px;
    animation-name: animationsaying2;
    animation-duration: 20s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
  }

  @keyframes animationsaying2 {

    0% {
      transform: rotate(0deg) translateY(100px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(200px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(100px) rotate(0deg);
    }
  }

  .animation-saying-3 {
    position: absolute;
    /* right: 20px; */
    top: 250px;
    left: 200px;
    animation-name: animationsaying3;
    animation-duration: 10s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;

  }

  @keyframes animationsaying3 {


    0% {
      transform: rotate(0deg) translateY(0px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(300px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(0px) rotate(0deg);
    }
  }

  .animation-saying-6 {
    position: absolute;
    /* right: 20px; */
    top: 170px;
    left: -50px;
    animation-name: animationsaying6;
    animation-duration: 10s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
  }

  @keyframes animationsaying6 {


    0% {
      transform: rotate(0deg) translateY(-170px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(-120px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(-170px) rotate(0deg);
    }
  }

  .animation-saying-5 {
    position: absolute;
    /* right: 20px; */
    top: 160px;
    left: -50px;
    animation-name: animationsaying5;
    animation-duration: 20s;
    /* animation-delay: -2s; */
    animation-iteration-count: infinite;
    width: 70px;
  }

  @keyframes animationsaying5 {

    0% {
      transform: rotate(0deg) translateY(-200px) rotate(0deg);
    }

    50% {
      transform: rotate(180deg) translateY(-190px) rotate(-180deg);
    }

    100% {
      transform: rotate(0deg) translateY(-200px) rotate(0deg);
    }
  }


}







/* 
  @media screen and (min-width: 320px) and (max-width: 776px) {
    .css-1su9lvs-MuiPaper-root-MuiAppBar-root{
      width: 91% !important;
    }
  } */

@media screen and (min-width: 1400px) and (max-width: 2800px) {
  .line-image-height {
    height: 566px;
  }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
  .ask-to-sign h2 {
    /* font-weight: 700; */
    justify-content: center;
    font-size: 15px !important;
  }
}

.loader-effect {
  padding: 10px 50px;
  background-color: white;
  border-radius: 5px;

  /* padding: 0px; */
  /* margin: 10px; */
}

.about-header {
  position: relative;
  background-image: url(./Images/brooke-cagle--uHVRvDr7pg-unsplash.jpg);
  height: 400px;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* Optional: Center text horizontally */
}

.about-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity as needed */
  backdrop-filter: blur(5px);
  /* Optional: add blur for a more pronounced filter effect */
  z-index: 1;
}

.about-header>* {
  position: relative;
  z-index: 2;
}

.spaceer {
  height: 1000px;
}

.about-header h1 {
  position: relative;
  z-index: 999;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 45px;
  font-weight: bold;
}

.about-header p {
  position: relative;
  z-index: 999;
  color: white;
  text-align: center;
  font-weight: 400;
}

.our-team {
  position: relative;
  padding-top: 48px;
  padding-bottom: 48px;

  opacity: 1;
  background: linear-gradient(195deg, #42424a, #191919);
  color: #ffffff;
  border-radius: none;
  box-shadow: none;
}

.our-team h1 {
  margin: 0;
  font-size: 1.875rem;
  line-height: 1.375;
  color: #344767;
  font-weight: 700;
  letter-spacing: 0em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #ffffff;
  letter-spacing: -0.125px;
}

.our-card {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  overflow: visible;
  margin-top: 24px;
  /* height: 150px; */
}

.our-card-inner-image {
  width: 100%;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: 0.375rem;
  box-shadow: 0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1), 0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05);
  height: 150px;
  object-fit: cover;

}

.our-card-inner-image-upper {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
  position: relative;
  top: -31px;
}

.privacy-policy-container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.privacy-policy-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  /* margin-top: 100px; */
}

.privacy-policy-container p {
  margin-bottom: 15px;
}


.main-header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  background: linear-gradient(to right, #f10b80, #830d9a);

  /* float: right; */

  border: 2px solid #f10b80;
  height: 92px;
}

.main-header-section h1 {
  font-size: 2.5rem;
  color: #ffffff;
  font-size: 23px;
}

.mein-header-blog h1 {
  font-size: 25px;
}

.mein-header-blog-left {
  display: flex;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
}

.mein-header-blog-left p {
  justify-content: space-around;

}

.mein-header-blog-right img {
  max-width: 400px;
}

.mein-header-blog-video {
  height: 234px;
  background-image: url(https://www.webisolution.com/wp-content/uploads/2021/12/breadcrum-banner.gif);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;

}

.mein-header-blog-video h1 {
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: 500;
}

.mein-header-blog-video:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(https://www.webisolution.com/wp-content/themes/kallyas/images/glare-effect.png);
  background-repeat: no-repeat;
  background-position: center top;
}

.span-blog {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 30px;
  border-radius: 100px;
  text-align: center;
  position: relative;

}

.blog-border {
  border-radius: 10px;
}

.amount-bar-square {
  border-radius: 5px !important;
  border: 2px dashed #DDDDDD !important;
}

.form-control:disabled {
  background-color: transparent !important;
  opacity: 1;
}

.customIcon {
  margin-top: 15px !important;
}

@media screen and (min-width: 70px) and (max-width: 980px) {
  .row-top-margin {
    margin-top: 50px !important;
  }
}

.reduis_angle {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  border: 1px solid #5a7184;
}

.reduis_angle .fa-facebook-f {
  color: #5a7184;
  padding: 16px 19px;
}

.reduis_angle .faXTwitter {
  color: #5a7184;
  padding: 0px 16px;
}

.reduis_angle .fa-instagram {
  color: #5a7184;
  padding: 16px 17px;
}

.reduis_angle .fa-pinterest {
  color: #5a7184;
  padding: 16px 17px;
}

.reduis_angle .fa-youtube {
  color: #5a7184;
  padding: 16px 15px;
}

.reduis_angle .fa-reddit-alien {
  color: #5a7184;
  padding: 16px 17px;
}

.footer_nd_list li {
  display: inline-block;
  padding: 4px 4px;
}

.footer_nd_list {
  /* float: right; */
}




.form-group {
  margin-bottom: 20px;
}

.hashtag-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hashtag-input textarea {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  resize: none;
  min-height: 60px;
}

.hashtag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.hashtag-tag {
  background-color: #FEEEF6;
  padding: 9px 24px 9px 15px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  margin-top: 20px;
  margin-right: 10px;
  color: #373737;

  /* Border with gradient */
  /* border: 2px solid #f10b80; */
  /* border-image: linear-gradient(to right, #f10b80, #830d9a) 10; */

  /* Gradient text color */
  /* background-image: linear-gradient(to right, #f10b80, #830d9a); */
  /* -webkit-background-clip: text; */
  /* For Safari */
  /* color: transparent; */
  /* Make the text transparent to show the gradient */
}


.remove-hashtag {
  color: white;
  padding: 10px 10px;
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;

}
.remove-hashtag-add{
  color: white;
  padding: 10px 10px;
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: -13px;
  right: -10px;
}
.hashtag-item-span-add{
  background-color: #FEEEF6 !important;
  padding: 10px 20px 10px 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  margin-top: 20px;
  margin-right: 10px;
  color: black;


}

.icon-remove-color-set {
  fill: #f10b80;
  width: 10px;
}

.remove-hashtag:hover {
  background-color: #dedede;
}

.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}



.text-danger {
  color: red;
  font-size: 12px;
}

.add-hashtags {
  width: 100%;
  outline: none;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 30px;
  background-image: url('./Images/Rectangle\ 5\ \(Stroke\)\ \(Stroke\)\ \(1\).svg');
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.why-choose-card {
  height: 100% !important;
}

/* Style the entire toast */
.custom-toast {
  background: linear-gradient(to right, #F10B80, #830D9A) !important;
  color: #fff !important;
}

/* Style the toast icon */
.custom-toast .Toastify__toast-icon>svg {
  fill: #fff !important;
  /* Makes the toast icon white */
}

/* Style the close button (cross icon) */
.custom-toast .Toastify__close-button>svg {
  fill: #fff !important;
  /* Makes the close icon white */
}

/* Style the toast body (text) */
.custom-toast-body {
  color: #fff !important;
}

.hashtags-container {
  margin-top: 30px;
  font-family: Arial, sans-serif;
}

.hashtags-title {
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.hashtags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 18px !important;
}

.hashtag-item {
  background: linear-gradient(to right, #F10B80, #830D9A) !important;
  color: #fff;
  padding: 8px 16px;
  border-radius: 7px;
  font-size: 16px;
  cursor: pointer;
  /* transition: transform 0.2s ease; */
  transition: all 0.5s;
  text-transform: capitalize;
  display: flex;
  gap: 5px;
  align-items: center;
}

.hashtag-item img {
  width: 16px;
  height: 16px;
}

.hashtag-item-span {
  display: flex;
  gap: 7px;
  align-items: center;
}

.hashtag-item-span img {
  width: 17px;
  height: 17px;
}

.hashtag-item:hover {
  transform: scale(1.05);
}

.form-label-verify-hashtags {
  float: right;
  background: linear-gradient(to right, #f10b80, #830d9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  cursor: pointer;
}

.card-verify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #f10b80, #830d9a);
  border-radius: 30px;
  padding: 10px 10px;
  color: white;
  font-size: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Label styles */
.label-verify {
  /* font-weight: bold; */
}

/* Button styles */
.btn-verify {
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

/* Send request button */
.send-request-verify {
  padding: 5px 15px;
  background: white !important;
  border-radius: 100px !important;
  min-width: 150px;
  font-size: 16px;
  font-weight: 500;
}

.send-request-verify-span {
  background: linear-gradient(to right, #f10b80, #830d9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;



}

/* Pending button */
.pending-verify {
  background: #4ba3fc !important;
  color: white !important;
  border: none;
  padding: 5px 15px;
  border-radius: 100px !important;
  font-size: 16px;
  font-weight: 500;
  min-width: 150px;
}

.verified-verify {
  background: #49bb12 !important;
  color: white !important;
  border: none;
  padding: 5px 15px;
  border-radius: 100px !important;
  font-size: 16px;
  font-weight: 500;
  min-width: 150px;
}

.the-details {
  font-size: 18px;
  font-weight: 500;
  line-height: 25.1px;
  color: #636262;
  font-family: Poppins;

}

.ask-to-sign-scroll-hide {
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.ask-to-sign-scroll-hide::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}



.css-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.css-input-icon {
  position: absolute;
  left: 21px;
  /* Adjust based on padding */
  width: 16px;
  height: 16px;
  color: #666;
}

.css-form-input {
  padding-left: 45px !important;
  /* Adjust to create space for the icon */
}

.css-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.css-modal {
  width: 90%;
  max-width: 355px;
  background: rgba(240, 240, 240, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.css-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.css-check-icon {
  background: #e6007e;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.css-title {
  font-size: 18px;
  font-weight: 500;
}

.css-subtitle {
  color: #666;
  font-size: 14px;
  margin-bottom: 16px;
}

.css-input-fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.css-input-fields input {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: rgba(200, 200, 200, 0.5);
  font-size: 16px;
  color: #333;
}

.css-proceed-btn {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(to right, #e6007e, #a829c3);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.2s;
}

.css-proceed-btn:hover {
  opacity: 0.9;
}

.custom-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.custom-button {
  width: 140px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: center;
  cursor: pointer;
  clip-path: polygon(0 100%, 100% 0, 100% 100%);
  transition: transform 0.3s ease-in-out;
}

.custom-save-draft {
  background: black;
  color: white;
  border-radius: 20px;
  /* HTML: <div class="triangle"></div> */

  --r: 15px;
  /* border radius */

  width: 170px;
  position: relative;
  aspect-ratio: 1;
  border-bottom-left-radius: var(--r);
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  --_g: calc(50%*(1 + 1/tan(22.5deg)) - var(--r)/(3*sqrt(2) - 4));
  --_r: calc(var(--r)/tan(22.5deg)), #000 98%, #0000 101%;
  -webkit-mask:
    radial-gradient(var(--r) at bottom var(--r) right var(--_r)),
    radial-gradient(var(--r) at left var(--r) top var(--_r)),
    conic-gradient(from 202.5deg at var(--_g) calc(100% - var(--_g)), #000 45deg, #0000 0);
  background: linear-gradient(45deg, #000000, #000000);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-save-draft p {
  color: white;
  position: absolute;
  /* top: 90px;
  left: 10px; */
  margin-top: 50px;
  left: 15px;
}

.custom-search {
  /* HTML: <div class="triangle"></div> */

  --r: 15px;
  /* border radius */
  position: relative;
  width: 170px;
  aspect-ratio: 1;
  border-bottom-right-radius: var(--r);
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  --_g: calc(50%*(1 + 1/tan(22.5deg)) - var(--r)/(3*sqrt(2) - 4));
  --_r: calc(var(--r)/tan(22.5deg)), #000 98%, #0000 101%;
  -webkit-mask:
    radial-gradient(var(--r) at bottom var(--r) left var(--_r)),
    radial-gradient(var(--r) at right var(--r) top var(--_r)),
    conic-gradient(from 112.5deg at calc(100% - var(--_g)) calc(100% - var(--_g)), #000 45deg, #0000 0);
  background: linear-gradient(45deg, #F10B80, #830D9A);
  display: flex;
  align-items: center;
  margin-left: 44px;
  cursor: pointer;

}

.custom-search p {
  color: white;
  position: absolute;
  /* top: 90px;
  left: 10px; */
  margin-top: 50px;
  right: 30px;
}

.custom-add-photos {
  position: absolute;
  display: flex;

  align-items: center;
  justify-content: center;

  /* HTML: <div class="triangle"></div> */

  --r: 18px;
  /* border radius */

  height: 180px;

  aspect-ratio: 2;
  --_g: calc(var(--r)/tan(22.5deg)) top var(--r), #000 98%, #0000 101%;
  -webkit-mask:
    conic-gradient(from 157.5deg at 50% calc(var(--r)/(3*sqrt(2) - 4) - 100%/tan(22.5deg)), #000 45deg, #0000 0) 0 0/100% calc(100% - var(--r)/sqrt(2)) no-repeat,
    radial-gradient(var(--r) at 50% calc(100% - var(--r)*sqrt(2)), #000 98%, #0000 101%),
    radial-gradient(var(--r) at left var(--_g)),
    radial-gradient(var(--r) at right var(--_g));
  clip-path: polygon(50% 100%, 100% 0, 0 0);
  background: linear-gradient(45deg, #D9D9D9, #D9D9D9);
  cursor: pointer;
  flex-direction: column;

}

.custom-add-photos p {
  font-size: 16px;
  color: #7C7C7C;
  margin-top: 8px;
}

.custom-photo-icon {
  /* background: url(photo-icon.png) no-repeat center center; */
  background-size: contain;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: -25px !important;
  margin-bottom: -11px;
  margin-top: -44px;
}


.form-input-modal:focus {
  color: none;
  background-color: none;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.custom-add-photos {
  align-items: center !important;
}

.custom-save-draft p {
  color: white;
  position: absolute;
  margin-top: 50px;
  left: 15px;
  bottom: 7px;
}

.custom-search p {
  color: white;
  position: absolute;
  margin-top: 50px;
  right: 30px;
  bottom: 7px;
}


.new-scroll-add {
  width: 100% !important;
}
.hastag-remove{
  position: absolute;
  top: -12px;
  right: -9px;
  background: rgb(255, 255, 255) !important;
  color: #C30C8C !important;
  border: 2px solid #EDBFD7 !important;
font-size: 10px;
width: 25px;
height: 25px;
}
.hashtag-list-scrollbar::-webkit-scrollbar {
  width: 2px;
}
.hashtag-list-scrollbar::-webkit-scrollbar-thumb {
  background-color: #B40FE7;
  border-radius: 10px; /* Optional: for rounded edges */
}
.hashtag-list-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Keeps track background invisible */
}
.hashtag-list-scrollbar {
  scrollbar-width: thin; /* For Firefox: makes scrollbar thin */
  scrollbar-color: #B40FE7 transparent; /* Scrollbar color and track background */
}
.hashtag-modal-search-button-skip {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  float: right;
  width: 200px;

 

  text-align: center;
  /* float: right; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 2px solid #f10b80;
}
.applied-hashtags{
  font-size: 20px;
  font-weight: 600;
}

.button-wrapper {
  display: flex;
  width: 80%;
  height: 50px; /* Same height for both buttons */
  gap: 10px;
 
}

.button-add-photos {
  flex: 1 !important; /* Occupies 50% width */
 
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px; /* Space between icon and text */
  background: linear-gradient(90deg, #F10B80, #830D9A) !important; /* Gradient background */
  color: transparent !important; /* Makes text color transparent */
  -webkit-background-clip: text !important; /* Applies gradient to text */
  background-clip: text !important;
  border: 2px solid #ff00ae !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  min-width: 273px !important;
}

.button-add-photos-2 {
  flex: 1 !important; /* Occupies 50% width */
 background-color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px; /* Space between icon and text */
 color: #ff00ae !important;
  border: 2px solid #ff00ae !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  min-width: 273px !important;
}

.button-add-photos:hover {
  background: linear-gradient(90deg, #ff0080, #8000ff);
  color: #fff;
}

.add-photos-icon {
  font-size: 18px; /* Icon size */
}

.button-search {
  flex: 1; /* Occupies 50% width */
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #F10B80, #830D9A);
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 273px !important;
}

.button-search:hover {
  background: linear-gradient(90deg, #830D9A, #F10B80);
}
@media screen and (min-width:320px) and (max-width: 776px) {
  .button-wrapper {
    display: flex;
    width: 10 0%;
    height: 50px; /* Same height for both buttons */
    gap: 10px;
   flex-direction: column;
  
  }
}
.btn-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
.content-wrapper {
 /* Occupies the full viewport height */
  overflow-y: auto; /* Enables scrolling if content overflows */
}
.blog-height{
  height: 100%;
}
.blog-content a {
  color: #f10b80;
  text-decoration: none; /* Optional: to remove underline */
}

.blog-content a:hover {
  text-decoration: underline; /* Optional: to show underline on hover */
}
.black-all-div{
  background-color: #1C1C1C;
}
.dark-theme-only-color{
  box-shadow: none !important ;
}
.add-new-shadow{
  box-shadow: 1px 1px 15px rgba(112, 112, 112, 0.345);
  border-radius: 10px;
}
.gradient-text {
  background: linear-gradient(90deg, #850D9B, #F10B80);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.border-right {
  border-right: 1px solid #ccc; /* Adjust color as needed */
  padding-right: 8px; /* Optional spacing */
}
.link-modal{
  color: #0E76C0;
  text-decoration: underline;
  cursor: pointer;
}
.view-details-link{
  position: relative;
  left: -70px;
  color: #B40FE7;
  font-weight: 400;
}

.dark-theme-only-color-filter{
  background-color: rgb(28 28 28) !important;
}
.back-to-ask-human{
  text-align: center;
  color: #4C4C4C;
}
.react-tel-input .flag-dropdown{
  border-radius: 110px 0px 0px 100px !important;
}
.contry-code-fixed{
  background: unset;
  overflow: hidden !important;
  border-radius: 100px 0px 0px 100px !important;
  padding: 27px 6px 21px 60px !important;
  width: 135px;
  padding: 12.5px 14px 12.5px 60px;
  border: 1px solid rgb(202, 202, 202);
}
.css-form-input-new-reduis{
  border-radius: 0px 100px 100px 0px !important;
}
.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
}
.react-tel-input .flag-dropdown.open .selected-flag{
  background-color: transparent !important;
}
.react-tel-input .flag-dropdown.open .selected-flag:hover{
  background-color: transparent !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
  background-color: transparent !important;
}
.react-tel-input .flag-dropdown{
  border: none !important;
  background-color: transparent !important;
}
.ask-to-sign{
  padding: 0px 70px;
  width: 100%;
}
.google-btn-icon{
  margin-top: 5px;
  margin-right: 5px;
  height: 15px;
}
.apple-btn{
  padding: 10px 40px;
  border: 1px solid #989898;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  background-color: #000000;
  border-radius: 100px;
}
.login-right-section {
  /* display: flex; */
  align-items: center; 
  justify-content: center; 
  height: 100vh;
}
.delete-account li{
  text-decoration: dotted !important;
  list-style: disc !important;
  color: #7D7D7D;
}
.delete-btn{
  background: black;
  /* color: white; */
  padding: 10px 30px;
  border-radius: 100px;
  width: auto;
  text-align: center;
  color: white;
  border: 2px solid #000000;
  width: 35%;
}
.logoutt-btn {
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 12px 30px;
  border-radius: 100px;
  text-align: center;
  position: relative;
  width: 35%;
}
.css-1xieton-MuiPaper-root-MuiAccordion-root::before{
  display: none !important;
}
.accordion-setup{
  border-radius: 10px !important;
}
.hastags-save{
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 70px;
  border-radius: 100px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}
.add-background{
  background-color: #ECECEC !important;
}
.add-background .add-hashtags{
  background-color: #ECECEC !important;
}
.remove-hashtag-new {
  color: white;
  border: 2px solid #EDBFD7;
  padding: 6px 6px 4px 5px;
  background-color: white;
  color: #C30C8C;
  /* background-image: linear-gradient(to right, #f10b80, #830d9a); */
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: -8px;
}
.remove-hashtag-new img{
  fill: #C30C8C;
}
.hashtag-tag-new{
  background-color: #EDBFD7;
  padding: 9px 24px 9px 15px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  margin-top: 20px;
  margin-right: 10px;
  color: #373737;
}
.profile-lebel{
  color: black;
  font-weight: 500;
}
.Mui-selected{
  background-color: #FBF0F9 !important;
  border-radius: 5px !important;
  color: #3A383A !important;
}
.profile-border {
  position: relative;
  display: inline-block;
  padding: 10px; /* Adjust padding as needed */
  border-radius: 20px;
  background: linear-gradient(white, white) padding-box, 
              linear-gradient(45deg, #F10B80, #870D98) border-box;
  border: 2px solid transparent; /* Transparent border to allow gradient */
  border-right: 2px solid transparent !important;
}
.logout-btn-2{
  background-image: linear-gradient(to right, #f10b80, #830d9a);
  color: white;
  padding: 10px 70px;
  border-radius: 100px;
  text-align: center;
  /* max-width: 400px; */
  width: 100%;
  text-align: center !important;
}
.new-side-scroll {
  height: 50vh;
  overflow-y: auto;
  padding: 10px;
}

/* Webkit Browsers (Chrome, Safari) */
.new-side-scroll::-webkit-scrollbar {
  width: 2px; /* Scrollbar width */
  margin: 10px;
}

.new-side-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.new-side-scroll::-webkit-scrollbar-thumb {
  background: linear-gradient(#e0dfdf, #e0dfdf);
  border-radius: 10px;
}

.new-side-scroll::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(#e0dfdf, #e0dfdf);
}
.profile-border-dark-screen{
  background: black !important; 
  border: 2px solid #e0dfdf !important;
padding:  10px;
}
.profile-border-dark-screen {
  color: #1976d2;
  background: #000000 !important;
}
.dark-theme-only-color-new{
  color:  #F10B80 !important;
  opacity: 1 !important;
  
  /* background-clip: text; */
}
.refer_by_info-bg{
  background-color: #F5F5F5;
  
}
/* .react-tel-input .country-list .search-box {
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 3px 8px 5px;
  outline: none;
  background: #000000 !important;
}
.react-tel-input .country-list .search {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: #1c1c1c !important;
  padding: 10px 0 6px 10px;
}
.react-tel-input .country-list .country.highlight {
  background-color: #373737 !important;
}
.react-tel-input .country-list .country:hover {
  background-color: #373737 !important;
} */
@media screen and (min-width: 320px) and (max-width: 776px) {
  .login-left-section{
    display: none !important;
  }
}
.ask-to-sign-img{
  max-width: 151px;
}
.css-1qqynwt-MuiTypography-root{
  font-family: Poppins !important;
}
.add-new-shadow-sticky-nav::-webkit-scrollbar {
  width: 4px; /* Scrollbar width */
  height: 50px; 
}

.add-new-shadow-sticky-nav::-webkit-scrollbar-thumb {
  background-color: #f10b8257; /* Pink scrollbar */
  border-radius: 10px;
  
  height: 50px; /* Ensures the scrollbar thumb is at least 50px */
}

.add-new-shadow-sticky-nav::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}
.rmsc .dropdown-container {
  position: relative;
  outline: 0;
  background-color: var(--rmsc-bg);
  border: 1px solid #e0dcdc;
  border-radius: 100px !important;
  padding: 5px !important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: #f10b8245 0 0 0 4px !important;
  border-color: #F10B80 !important;
}
.custom-multi-select .rmsc .item input[type="checkbox"] {
  accent-color: pink !important; /* Changes checkbox color */
}

.custom-multi-select .rmsc .item input[type="checkbox"]:checked {
  background-color: pink !important; /* Ensures checked state is also pink */
}
/* Make checkbox pink */
.custom-multi-select .rmsc .dropdown-content .item input[type="checkbox"] {
  accent-color: pink !important;  /* Changes checkbox color */
}

/* Ensure checked state is clearly visible */
.custom-multi-select .rmsc .dropdown-content .item input[type="checkbox"]:checked {
  background-color: pink !important;  /* Background inside the checkbox */
  border-color: pink !important;      /* Border color */
}

input[type="checkbox"] {
  accent-color: #F10B80; /* Change fill color */
}

.back-slide{
  padding: 8px 25px;
  box-shadow: 1px 1px 5px rgb(225, 225, 225);
  border-radius: 100px;
  color: #1C1C1C;
}
.back-slide:hover{
  box-shadow: 1px 1px 5px rgb(133, 133, 133);
  transition: all 0.2s;
}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 300px;
  max-height: 228px !important;
  overflow-y: scroll;
  border-radius: 7px;
}
.home-card-count{
  font-size: 18px;
}
.better-answers {
  background: linear-gradient(to bottom, #f8f8f8, #fccfe64f);
  padding: 10px;
  text-align: center;
}
.user-price {
  font-size: 24px;
  font-weight: 500 !important;
  background: linear-gradient(90deg,#F10B80 , #850D9B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.user-titels{
  padding: 10px !important;
}
.user-rating{
  color: #363636 !important;
  font-size: 15px !important;
  line-height: 26px !important;
}
.mark-check::after{
  content: '';
    bottom: -9px;
    background-image: url(./Images/checkmark.svg);
    width: 31px;
    height: 31px;
    position: absolute;
    right: -14px;
    background-repeat: no-repeat;
}
.mark-check-for-grid::after{
  content: '';
  bottom: -9px;
  background-image: url(./Images/checkmark.svg);
  width: 31px;
  height: 31px;
  position: absolute;
  right: -14px;
  background-repeat: no-repeat;
}
.hastags-logo{
max-width: 200px;
}
.side-bar-profile-background{
  background-color: #F5F5F5;
  border-radius: 10px;
}
.edit-button{

  background-color: white;
  padding: 2px 13px;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: 1px 1px 5px #c3bbbb;
}
.edit-button span{
  background: linear-gradient(90deg,#F10B80 , #850D9B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.side-bar-profile-background-right{
display: flex;
justify-content: end;
}
.info-age{
  background-color: #F4C6DE;
  padding: 4px 20px;
  color: black;
  border-radius: 5px;
}