// styles.scss

// .light-theme {
//   background-color: white; // Default background color for light theme
//   color: black; // Example: default text color for light theme
// }

// .dark-theme {
//   background-color: rgb(0, 0, 0); // Override for dark theme
//   color: white; // Example: change text color for dark theme
// }
.dark-theme {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
}
//  .dark-mode{
//   background-color: rgb(0, 0, 0) !important;
//   color: rgb(255, 255, 255) !important;
// }
.inner-shadow-dark{
  background-color: #1C1C1C !important;
}
.dark-theme-only-color{
  color: white !important;
  background-color: #1C1C1C !important;
}
.dark-theme-only-color-neww{
  color: white !important;
  background-color: #000000 !important;
}
.dark-theme-only-white{
  color: white !important;
}
.black-color{
  background-color: black !important;
}

.dark-theme-only-white-light{
  color: rgb(164, 164, 164) !important;
}
.dark-mode-pink-color{
  color: #D80B86 !important;
}
.message-bar-color{
  background-color: #26313B !important;
}
.dark-theme-only-color::placeholder{
  color: rgb(255, 255, 255);
  opacity: 0.5; /* Firefox */
}
.hover-white-color{
  color: white !important;
}
.user-chat:hover .hover-white-color {
  color: black !important;
}
.bar-icon-set{
  background-color: #4a4a4a !important;
  color: #ffffff !important;
}
.dropdown-chat-dark {
  background-color: rgb(0, 0, 0);
  color: white;
}
.dropdown-chat-dark-color {
  color: white;
}
.loader-color{
  background-color: #26313B;
  box-shadow: 0px 0px 5px rgb(255, 255, 255);
}