/* Grid view specific styles */
.grid-view-container {
    margin-top: 20px;
  }
  

  
  .grid-card .MuiCardContent-root {
    padding: 8px 16px;
    text-align: left;
  }

  .grid-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 35px !important;
    overflow: hidden;
    background: linear-gradient(to right, rgba(217, 217, 217, 0.1), #FCCEE6) !important;
    /* border: 1px solid #f0e0f0; */
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%) !important;
  }
  
  .grid-card .StyledAvatar {
    margin: 0 auto;
    border: 3px solid #f0e0f0;
  }
  
  
  /* List view specific styles */
  .list-view-container {
    margin-top: 20px;
  }
  
  .list-card {
    background: linear-gradient(to right, rgba(217, 217, 217, 0.1), #FCCEE6);
    margin-bottom: 16px;
    border-radius: 12px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .grid-card, .list-card {
      padding: 10px;
    }
    
    .grid-card .MuiCardContent-root,
    .list-card .MuiCardContent-root {
      padding: 10px;
    }
  }
  